import vl from './vimeolistener';

/**
 * Optional include. Keeping this off in production since there's no need
 * to bake in a library that won't be used.
 */
// const vimeo = window.Vimeo || require("@vimeo/player");

/**
 * When the DOM is ready, initialize the vimeo tracker.
 */
window.document.addEventListener("DOMContentLoaded", function() {
  /**
   * The Vimeo object should be available on the global scope at this point.
   * Scan for any existing iframes.
   */
  vl.init(window.Vimeo, window.document);
  console.log('document listeners enabled');

  /**
   * MMA widgets are initialized after the page is built,
   * so re-scan for any iframes they may contain.
   */
  $(window).on('onMMAWidgetsBuilt', function() {
    // TODO: why would there be a race condition here???
    window.setTimeout(function() {
      window.UIkit && window.UIkit.util.on('.uk-modal', 'show', function() {
        console.log('modal listeners enabled');
        vl.init(window.Vimeo, window.document);
      });
    }, 800);
  });

  /**
   * When any anchor link is clicked containing a vimeo reference, init.
   */
  [...window.document.querySelectorAll('a[href*="vimeo"]')].map(function(el) {
    el.addEventListener('click', function () {
      window.setTimeout(function() {
        vl.init(window.Vimeo, window.document);
      }, 800);
    }, false);
  });

});

/**
 * Just in case I need to access this object globally.
 */
window.vl = vl;

/**
 * Enable hot swapping for webpack.
 */
if (module.hot) {
  module.hot.accept(console.log.bind(console));
  console.info('hot swapped!!');
}
