let ws = {

  /**
   * Keep track of individual videos.
   * All videos will just be an object of properties, 
   * where the property is the ID of the video being watched,
   * and the value is an object containing:
   * {
   *   startTime: new Date(),
   *   lapTime: new Date(),
   *   endTime: new Date(),
   *   seconds: 0,
   * }
   */
  _videos: {},

  /**
   * Starts a timer on a particular video.
   * @param {string} videoId The video to keep track of.
   */
  startTimer: function(videoId, currentTime) {

    if (!videoId) {
      return null;
    }

    let key = videoId;

    /**
     * If currentTime was not passed, set it to the current date.
     */
    currentTime = currentTime || new Date();

    /**
     * Stop any other timers that may be running.
     */
    this.stopAllExcept(key, currentTime);

    /**
     * Check if we have an existing watch on this video.
     */
    if (this._videos[key]) {
      this._videos[key].lapTime = currentTime;
      this._videos[key].endTime = null;
      return this._videos[key];
    }

    /**
     * Otherwise, we're starting a completely new timer.
     * Start & lap time should be the same, and seconds should start at 0.
     */
    return this._videos[key] = {
      startTime: currentTime,
      lapTime: currentTime,
      endTime: null,
      seconds: 0,
    };

  },

  /**
   * Stops a timer on a particular video.
   * @param {string} videoId 
   */
  stopTimer: function(videoId, currentTime) {

    /**
     * If the timer was already stopped, don't stop it again
     * because calling stopTimer resets the end time.
     */
    if (!this.isRunning(videoId)) {
      return this._videos[videoId]?.seconds;
    }

    currentTime = currentTime || new Date();    

    this._videos[videoId].endTime = currentTime;

    let endTime = this._convertToSeconds(this._videos[videoId].endTime);
    let startTime = this._convertToSeconds(this._videos[videoId].lapTime);

    let elapsed = endTime - startTime;
    if (elapsed < 0) elapsed = 0;

    this._videos[videoId].seconds += elapsed;

    return elapsed;
  },

  /**
   * Gets the watch time of a particular video in seconds.
   * @param {string} videoId 
   * @returns {int} The watch time of the video, in seconds.
   */
  getWatchTime: function(videoId, currentTime) {
    /**
     * If we don't have a valid video, return 0.
     */
    if (!this._videos[videoId]) {
      return 0;
    }

    /**
     * If we don't have an end time, use the currentTime
     * passed to determine how many seconds have elapsed since the
     * startTime.
     */
    if (this.isRunning(videoId)) {

      currentTime = currentTime || new Date();

      /**
       * If a currentTime was passed, use that to determine how many seconds have passed.
       * Otherwise, just return 0.
       */
      var currentDifference = this._convertToSeconds(currentTime) - this._convertToSeconds(this._videos[videoId].lapTime);
      if (currentDifference < 0) currentDifference = 0;

      /**
       * Safe to update the running count of seconds when getWatchTime is called.
       */
      var cumulativeTotalSeconds = this._videos[videoId]?.seconds + currentDifference;
      // this._videos[videoId].seconds = cumulativeTotalSeconds;

      return cumulativeTotalSeconds;

    }

    /**
     * Otherwise, just return the number of seconds recorded.
     */
    return this._videos[videoId]?.seconds;
  },

  /**
   * Determines if a timer is currently running, 
   * based on endDate being null or undefined.
   * @returns {boolean} Whether the timer is running.
   */
  isRunning: function(videoId) {
    /**
     * For a video to be running, it must
     * 1) exist, and
     * 2) have its endDate be null or undefined.
     */
    return !!(this._videos[videoId] && !this._videos[videoId].endTime);
  },

  /**
   * Clears a specific video from the list of stopwatches.
   */
  clear: function(videoId) {
    delete this._videos[videoId];
  },

  /**
   * Clears the list of videos.
   */
  clearAll: function() {
    return this._videos = {};
  },

  /**
   * Stops all timers on any videos except for the video ID passed.
   * @param {string} videoId 
   * @param {Date} currentTime
   */
  stopAllExcept: function(videoId, currentTime) {

    for (const k in this._videos) {
      if ((videoId && k+'' !== videoId+'') || !videoId) {
        if (this.isRunning(k)) {
          this.stopTimer(k, currentTime);
        }
      }
    }

  },

  /**
   * Stops all timers.
   */
  stopAll: function(currentTime) {
    this.stopAllExcept(null, currentTime);
  },

  /**
   * Grabs a list of all videos watched with their watch times.
   * @returns {object} An array of objects containing all the videos with their watch times.
   *                   Objects return in this format: { video_id: '2', seconds: 100, is_running: true }
   */
  getAllWatched: function(currentTime) {

    var list = [];

    for (const videoId in this._videos) {
      list.push({
        'video_id': videoId,
        'seconds': this.getWatchTime(videoId, currentTime),
        'is_running': this.isRunning(videoId),
      });
    }

    return list;
  },

  _convertToSeconds: function(d) {
    /**
     * Make sure we have a valid date object.
     */
    if (d instanceof Date && !isNaN(d.valueOf())) {
      return Math.round(d.getTime() / 1000);
    }
    /**
     * Not sure what to do - just return 0.
     */
    return 0;
  }

};

export default ws;
