import ws from './videostopwatch';
import fetch from 'node-fetch';
import queryString from 'query-string';

export default {

  /**
   * Holds the timer object.
   */
  _ws: ws,

  /**
   * Holds an array of Vimeo player objects for each iframe that exists on a page.
   */
  _players: [],

  /**
   * Holds an active list of video IDs that have handlers attached to them.
   */
  _videoIds: [],

  /**
   * Flag to determine whether any trackers are active.
   * Defaults to false.
   * Only is true if it found vimeo iframes on the page.
   */
  _active: false,

  /**
   * Flag to determine if handlers have been attached.
   */
  _videoActionHandlersAttached: false,

  /**
   * Flag to determine if the beforeunload handler has been attached.
   */
  _visbilityChangeHandlerAttached: false,

  /**
   * Holds a periodic activity setTimeout reference.
   * We want to check every 30 minutes whether there's been any activity
   * because if there hasn't, and we have timers to send to the server,
   * and all timers are stopped, it should go ahead and send its data to
   * the server and not wait for the page to unload.
   */
  _idleTimer: null,

  /**
   * Holds the currently logged in uid.
   */
  _uid: null,

  /**
   * Whether a login has been requested.
   */
  _loginRequested: false,
  
  /**
   * Init function. 
   * Should only be run once the dom is ready for it.
   */
  init: function(Vimeo, document) {

    
    /**
     * If by some bizarre circumstance the document object is not available,
     * we can't do anything.
     */
    if (typeof document === 'undefined') {
      // console.warn('Document not found. Was this module not loaded in a browser?');
      return false;
    }
    
    /**
     * We can't do anything if we don't have a vimeo object.
     */
    if (typeof Vimeo === 'undefined') {
      console.warn('Video tracker module init failure: Vimeo player library not found. Video tracking cannot work without this.');
      return false;
    }
    
    /**
     * Find all vimeo iframes.
     */
    const frames = this.findAllVimeoIframes(document);
    this._setActive(frames.length > 0);
    this.invokeVimeoOnAll(frames, Vimeo.Player);
    
    /**
     * Add a window unload listener.
     */
    if (this.isActive()) {
      this.attachVisibilityChangeHandler(document);
      this._idleTimer = this.attachIdleTimer();
    }

    return frames;

  },

  /**
   * Gets the stopwatch (timer) object.
   */
  getTimer: function() {
    return this._ws;
  },
  
  /**
   * Sets whether the entire tracker is active.
   * @param {boolean} flag 
   * @returns {boolean} flag
   */
  _setActive: function(flag) {
    return this._active = flag; 
  },

  /**
   * Gets whether this tracker is active.
   * @returns {boolean} flag
   */
  isActive: function() {
    return this._active; 
  },

  /**
   * Finds all vimeo iframes.
   */
  findAllVimeoIframes: function(document) {
    return document.querySelectorAll('iframe[src*="vimeo"]');
  },

  /**
   * Finds a specific vimeo iframe by its video ID.
   */
  findVimeoIframeById: function(videoId) {
    return document.querySelector(`iframe[src*="${videoId}"]`);
  },

  /**
   * Invokes vimeo players on all iframes provided.
   */
  invokeVimeoOnAll: async function(frames, Player) {
    
    if (frames.length > 0) {
      
      frames.forEach(async (frame, i) => {

        let thisPlayer = new Player(frame);

        /**
         * Add the player object to the array.
         */
        this._players[i] = thisPlayer;

        /**
         * Obtain the video ID and attach event handlers.
         */
        let videoId = await thisPlayer.getVideoId();

        // console.log(this._videoIds);
        /**
         * Determine if we already have an event handler on this.
         */
        if (this._videoIds.includes(videoId)) {
          console.log('handler already attached');
        } else {
          this.attachPlayerEventHandlers(thisPlayer, videoId);
        }
        
      });


    }

    return this._players;

  },

  /**
   * Attaches handler for what should happen when the browser is closed.
   */
  attachVisibilityChangeHandler: function(document) {

    const vl = this;

    if (typeof window !== 'undefined') {

      /**
       * Attempt to stop all timers before visibilitychange is fired.
       */
      window.addEventListener("beforeunload", function(e) { 
        console.info('stopping timers...');
        vl.getTimer().stopAll();
      });
    }

    document.addEventListener("visibilitychange", function() {
      if (document.visibilityState === 'hidden') {
        vl.captureActionsCallback();
      }
    });

    /**
     * Doesn't actually confirm the handler was attached.
     * Just confirms this function was called.
     * addEventListener doesn't return anything.
     */
    this._visbilityChangeHandlerAttached = true;

  },

  /**
   * Sets a general 30-minute timer to check for inactivity.
   */
  attachIdleTimer: function() {

    // set the interval at 30 minutes.
    const interval = 30 * 60 * 1000;

    /**
     * Node.js returns an object for setTimeout, but window.setTimeout
     * returns a number.
     * See: https://github.com/nodejs/node-v0.x-archive/issues/5728
     */
    const windowSetInterval = (typeof window !== 'undefined') 
      ? window.setInterval
      : setInterval;

    return windowSetInterval(this.captureActionsCallback, interval);

  },

  /**
   * Attaches handlers to the provided vimeo player object.
   */
  attachPlayerEventHandlers: function(player, videoId) {

    // start actions
    player.on('play', () => this.startActionsCallback(videoId, player));

    // stop actions
    player.on('pause', () => this.stopActionsCallback(videoId, player));
    player.on('ended', () => this.stopActionsCallback(videoId, player));

    this._videoActionHandlersAttached = true;
    this._videoIds.push(videoId);

  },

  /**
   * Callback for everything that should happen when a video is played.
   * Gets attached to the vimeo player object's on('play') event.
   * @returns 
   */
  startActionsCallback: async function(videoId, player) {
    if (!videoId) return;

    // if (this.checkLoggedIn(videoId, player)) {

      let timestamp = this._getCurrentTime();

      console.info('starting timer on ', videoId);
      console.info('current time', timestamp);
      this.getTimer().startTimer(videoId, timestamp);
      // console.log(player);

      let videoTitle = await player.getVideoTitle();

      // send the data to the server.
      let formData = new FormData();

      formData.append('video_id', videoId);
      formData.append('video_title', videoTitle);
      formData.append('uid', Drupal.settings.mmaglobal.user.uid);
      formData.append('email', Drupal.settings.mmaglobal.user.email);
      formData.append('is_member', Drupal.settings.mmaglobal.user.is_member);

      formData.append('utm_id', queryString.parse(location.search)?.utm_id || '');
      formData.append('utm_source', queryString.parse(location.search)?.utm_source || '');
      formData.append('utm_medium', queryString.parse(location.search)?.utm_medium || '');
      formData.append('utm_campaign', queryString.parse(location.search)?.utm_campaign || '');
      formData.append('utm_term', queryString.parse(location.search)?.utm_term || '');

      formData.append('action', 'start');

      fetch('/mmav/beacon/video', {
        headers: {'X-Requested-With': 'XMLHttpRequest'},
        method: "post",
        body: formData,
      });

    // }

  },

  /**
   * Callback for everything that should happen when a video is stopped.
   * Stopped, meaning:
   * 1) The user pressed pause
   * 2) The video reached the end
   * Gets attached to the vimeo player object's on('pause') and on('ended') event.
   * @returns 
   */
  stopActionsCallback: async function(videoId, player) {
    if (!videoId) return;

    let timestamp = this._getCurrentTime();

    console.info('stopping timer ', videoId);
    this.getTimer().stopTimer(videoId, timestamp);

    let watchtime = this.getTimer().getWatchTime(videoId, timestamp);
    console.info('current time', timestamp);
    console.info('watch time for ', videoId, watchtime);

    let videoTitle = await player.getVideoTitle();

    // send the data to the server.
    let formData = new FormData();

    formData.append('video_id', videoId);
    formData.append('video_title', videoTitle);
    formData.append('uid', Drupal.settings.mmaglobal.user.uid);
    formData.append('email', Drupal.settings.mmaglobal.user.email);
    formData.append('is_member', Drupal.settings.mmaglobal.user.is_member);

    formData.append('utm_id', queryString.parse(location.search)?.utm_id || '');
    formData.append('utm_source', queryString.parse(location.search)?.utm_source || '');
    formData.append('utm_medium', queryString.parse(location.search)?.utm_medium || '');
    formData.append('utm_campaign', queryString.parse(location.search)?.utm_campaign || '');
    formData.append('utm_term', queryString.parse(location.search)?.utm_term || '');

    formData.append('action', 'stop');
    formData.append('watch_duration', watchtime);

    fetch('/mmav/beacon/video', {
      headers: {'X-Requested-With': 'XMLHttpRequest'},
      method: "post",
      body: formData,
    });

    return watchtime;
  },

  captureActionsCallback: function() {

    /**
     * If for some bizarre reason the navigator object is not available,
     * we can't do anything.
     */
    if (typeof navigator === 'undefined') return false;

    console.log('sending beacon to server ... (not really)');

    // get a list of all timers and send to the server

    // clear the list of timers

  },

  /**
   * Checks if the user is logged in.
   */
  isLoggedIn: function() {
    return !!this.getUid();
  },

  /**
   * Gets the UID of the currently logged in user.
   */
  getUid: function() { 
    return this._uid;
  },

  /**
   * Sets the UID of the currently logged in user.
   */
  setUid: function(uid) {
    this._uid = uid;
  },

  /**
   * Alias for setUid.
   */
  login: function(uid) {
    return this.setUid(uid);
  },

  /**
   * Alias for setUid = null;
   * NOTE: Doesn't actually log out the user in the system.
   * All this does is clear out the UID from this module.
   */
  logout: function() {
    return this.setUid(null);
  },

  /**
   * Checks if a user is logged in.  If not, requests a login.
   */
  checkLoggedIn: function(videoId, player) {

    if (!this.isLoggedIn()) {

      /**
       * Ask the player to stop the video.
       */
      // player && player.pause();

      /**
       * Ask the system to produce a modal popup window to have the user
       * either login or create a free account.
       */
      this.requestLogin(videoId);
    }

    return this.isLoggedIn();

  },

  /**
   * Dispatches an event requesting an MMA login panel to be rendered.
   */
  requestLogin: function(videoId) {
    window.dispatchEvent(new Event('mma_login_requested'));
    this._loginRequested = true;
  },

  /**
   * Function to return the current time, as a JS Date object.
   */
  _getCurrentTime: function() {
    return new Date();
  },

};

/*

.vimeo-iframe-request-login-wrapper {
    position: relative;
}

.vimeo-iframe-request-login-wrapper::before {
    content: " ";
    position: absolute;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
}

 */